import { DeviceVerificationEntryInterface, UserVerifyRequestParams, userVerifyActions } from "./../../lib/userVerify/userVerifyParams";
import { AuthenticationMethods, GlobalStorageKeys } from "@/interfaces/applicationSettings";
import TimeClient from "@/lib/time/timeClient";
import { timeActions } from "@/lib/time/timeParams";
import router from "@/router";
import { notIntendedRoutes, notRedirectableRoutes, routerPaths } from "@/router/routerPaths";
import { ActionContext, Module, ActionTree } from "vuex";
import { SellerAuthentication, SellerInterface } from "@/interfaces/seller";
import { UserInterface } from "@/interfaces/user";

import lsInit from "@/lib/localStorageSlimInit";
import SontClient from "@/lib/userVerify/userVerifyClient";
import { nextTick } from "vue";
const ls = lsInit();

// state
export const state = {
  initialized: false,
  user: null as null | UserInterface,
  isLoggedIn: false,
  userPswUpdated: false,
  accountUpdated: false,
  locale: "lt",
  isManager: false,
  isUser: false,
  isSupervisor: false,
  isSeller: false,
  sessionExpires: null,
  sessionTimeout: 0,
  sessionExpired: false,
  applicationsInSession: [],
  additionalInfo: null as null | SellerInterface,
  is2FaVerified: false,
  is2FaEnabled: false,
  twoFaDeviceToken: "",
  is2FaVerificationPending: false,
  isSharedUser: false,
};

// getters
export const getters = {
  isInitialized: (state: { initialized: boolean }) => state.initialized,
  user: (state: { user: UserInterface | null }) => state.user,
  isLoggedIn: (state: { isLoggedIn: boolean }) => state.isLoggedIn,
  isManager: (state: { isManager: boolean }) => state.isManager,
  isSupervisor: (state: { isSupervisor: boolean }) => state.isSupervisor,
  isUser: (state: { isUser: boolean }) => state.isUser,
  isSeller: (state: { isSeller: boolean }) => state.isSeller,
  userPasswordUpdated: (state: { userPswUpdated: boolean }) => state.userPswUpdated,
  userAccountUpdated: (state: { accountUpdated: boolean }) => state.accountUpdated,
  locale: (state: { locale: string }) => state.locale,
  sessionExpires: (state: { sessionExpires: number }) => state.sessionExpires,
  sessionTimeout: (state: { sessionTimeout: number }) => state.sessionTimeout,
  isSessionExpired: (state: { sessionExpired: boolean }) => state.sessionExpired,
  applicationsInSession: (state: { applicationsInSession: number[] }) => state.applicationsInSession,
  additionalInfo: (state: { additionalInfo: SellerInterface | null }) => state.additionalInfo,
  is2FaEnabled: (state: { is2FaEnabled: boolean }) => state.is2FaEnabled,
  is2FaVerified: (state: { is2FaVerified: boolean }) => state.is2FaVerified,
  twoFaDeviceToken: (state: { twoFaDeviceToken: string }) => state.twoFaDeviceToken,
  isLimitedLogin: (state: { user: { isLimitedLogin: boolean } }) => state.user?.isLimitedLogin || false,
  is2FaVerificationPending: (state: { is2FaVerificationPending: boolean }) => state.is2FaVerificationPending || false,
  isSharedUser: (state: { isSharedUser: boolean }) => state.isSharedUser || false,
  isNotRequiredDocuments: (state: { additionalInfo: { NeprivalomiDokumentai: number } }) =>
    state.additionalInfo?.NeprivalomiDokumentai == 1 || false,
  isLiabilitiesVisible: (state: { user: { MatoIsipareigojimus: boolean } }) => !!+state.user?.MatoIsipareigojimus || false,
};

// mutations
export const mutations = {
  initialize(state: { initialized: boolean }, payload: boolean) {
    state.initialized = payload;
  },
  setUser(state: { user: null | UserInterface }, payload: any) {
    state.user = payload;
  },
  userLogin(
    state: {
      user: UserInterface | null;
      isLoggedIn: boolean;
      isUser: boolean;
      isManager: boolean;
      isSupervisor: boolean;
      isSeller: boolean;
    },
    { user }: unknown
  ) {
    state.user = user;
    state.isLoggedIn = true;
    state.isUser = true;

    switch (user.Role) {
      case 1: // ROLE_MANAGER
      case "1":
        state.isManager = true;
        break;
      case 3: // ROLE_SUPERVISOR
      case "3":
        state.isSupervisor = true;
        state.isSeller = true;
        break;
      case 0: // ROLE_USER
      case "0":
      default:
        state.isSeller = true;
        break;
    }
  },
  userLogout(state: {
    user: UserInterface | null;
    isLoggedIn: boolean;
    locale: string;
    isManager: boolean;
    isUser: boolean;
    isSupervisor: boolean;
    isSeller: boolean;
    sessionExpired: boolean;
    sessionExpires: number | null;
  }) {
    ls.set(GlobalStorageKeys.INTENDED_ROLE, state.user?.Role || "");

    state.user = null;
    state.isLoggedIn = false;
    state.isLoggedIn = false;
    state.isManager = false;
    state.isUser = false;
    state.isSupervisor = false;
    state.isSeller = false;
    ls.remove(GlobalStorageKeys.APPLICATION_USER);

    state.sessionExpires = null;
    ls.remove(GlobalStorageKeys.SESSION_EXPIRES);
    state.sessionExpired = false;

    if (
      !notIntendedRoutes.includes(router.currentRoute.path as routerPaths) &&
      !notIntendedRoutes.includes(router.currentRoute.name as routerPaths)
    ) {
      ls.set(GlobalStorageKeys.INTENDED_URL, router.currentRoute.path);
    }

    sessionStorage.clear();

    if (router.currentRoute.path !== routerPaths.LOGIN) {
      router.replace({ path: routerPaths.LOGIN }); //.catch(() => {});
    }
  },
  setLocale(state: { locale: string }, locale: string) {
    state.locale = locale;
    ls.set(GlobalStorageKeys.APPLICATION_LOCALE, locale);
  },
  notifyAboutPasswordUpdate(state: { userPswUpdated: boolean }, updated: boolean) {
    state.userPswUpdated = updated;
  },
  notifyAboutUpdatedAccount(state: { accountUpdated: boolean }, updated: boolean) {
    state.accountUpdated = updated;
  },
  setSessionExpires(state: { sessionExpires: number }, sessionExpires: number) {
    state.sessionExpires = sessionExpires;
    ls.set(GlobalStorageKeys.SESSION_EXPIRES, String(sessionExpires), { encrypt: false });
  },
  setSessionTimeout(state: { sessionTimeout: number }, newSessionTimeout: number) {
    state.sessionTimeout = newSessionTimeout;
  },
  setSessionExpired(state: { sessionExpired: boolean }, expiration: boolean) {
    state.sessionExpired = expiration;
  },
  setApplicationsInSession(state: { applicationsInSession: number[] }, applicationsInSession: number[]) {
    state.applicationsInSession = applicationsInSession.length
      ? [...new Set([...state.applicationsInSession, ...applicationsInSession])]
      : [];
    ls.set(GlobalStorageKeys.APPLICATIONS_IN_SESSION, state.applicationsInSession);
  },
  setAdditionalInfo(
    state: {
      is2FaVerified: any;
      is2FaEnabled: boolean;
      additionalInfo: SellerInterface | null;
      user: UserInterface;
      isManager: boolean;
      isSharedUser: boolean;
    },
    payload: SellerInterface | null
  ) {
    ls.set(GlobalStorageKeys.APPLICATION_USER_ADDITIONAL_INFO, payload);
    state.additionalInfo = payload;

    if (!state.user) {
      state.is2FaEnabled = false;
      return;
    }

    state.is2FaEnabled =
      +(state.additionalInfo?.DviejuFaktoriuPrisijungimas || "0") > 0 || (state.isManager && (state.user.Emailas?.length || 0) > 0);

    state.isSharedUser = state.additionalInfo?.ParduotuvesVartotojas == 1 || false;

    if (router.currentRoute.path === routerPaths.ROOT) {
      // this redirect will be handled from router config
      return;
    }

    // this tmp cookie might be set by the router before redirect to login
    // and we can use this value to redirect user back where he wanted after successfully login
    const intendedUrl: string = ls.get(GlobalStorageKeys.INTENDED_URL) || "";

    if (
      router.currentRoute.path === routerPaths.LOGIN ||
      router.currentRoute.path === routerPaths.PASSWORD_REMIND ||
      router.currentRoute.path === routerPaths.PASSWORD_UPDATE ||
      routerPaths.PASSWORD_RESET.replace(":token", router.currentRoute.params?.token) === router.currentRoute.path
    ) {
      if (state.is2FaEnabled && !state.is2FaVerified) {
        // 2fa verification is performed in the same login route
        return;
      }

      const authenticationMethod = state.user?.PardavejoVartotojas?.Autentifikacija;
      const sellerAuthMethod = authenticationMethod ? +authenticationMethod : AuthenticationMethods.NONE;
      if (state.user?.PardavejoVartotojas?.Autentifikacija !== undefined && sellerAuthMethod == AuthenticationMethods.NONE) {
        return;
      }

      if (!intendedUrl || !intendedUrl.length) {
        const intendedRole: string = ls.get(GlobalStorageKeys.INTENDED_ROLE) || "";
        ls.remove(GlobalStorageKeys.INTENDED_URL);
        ls.remove(GlobalStorageKeys.INTENDED_ROLE);

        if (intendedRole.length && state.user.Role == intendedRole && router.currentRoute.path !== routerPaths.NEWS) {
          router.push({ path: routerPaths.NEWS });

          return;
        }
      }

      if (intendedUrl === routerPaths.LOGIN || intendedUrl === router.currentRoute.path) {
        return;
      }

      if (intendedUrl.length && intendedUrl !== router.currentRoute.path) {
        router.push({ path: intendedUrl });

        return;
      }
    }

    // fallback redirect to news
    if (router.currentRoute.path !== routerPaths.NEWS && !notRedirectableRoutes.includes(router.currentRoute.path as routerPaths)) {
      router.push({ path: routerPaths.NEWS });
    }
  },
  set2FaVerification(state: { is2FaVerified: boolean }, payload: boolean) {
    if (!payload) {
      state.is2FaVerified = false;
      return;
    }

    state.is2FaVerified = payload;
  },
  set2FaDeviceToken(state: { twoFaDeviceToken: string }, payload: any) {
    state.twoFaDeviceToken = payload;
  },

  set2FaVerificationPending(state: { is2FaVerificationPending: boolean }, payload: boolean) {
    state.is2FaVerificationPending = payload;
  },
};

// actions
export const actions: ActionTree<typeof state, any> = {
  async initialize({ commit, dispatch }: ActionContext<typeof state, any>) {
    const sessionTimeout = parseInt(process.env.VUE_APP_SELLER_SESSION_TIMEOUT || "") || 30 * 60000;
    dispatch("setSessionTimeout", sessionTimeout);

    const user = await dispatch("loadUserFromStorage");
    if (!user || !user.user?.userName) {
      commit("initialize", true);

      return;
    }

    dispatch("setUserLoggedIn", user);

    const sessionExpiresStored: any = ls.get(GlobalStorageKeys.SESSION_EXPIRES, { encrypt: false });
    if (isNaN(sessionExpiresStored) || +sessionExpiresStored <= 0) {
      // this will make call to time sync api
      return dispatch("extendSession").then(() => {
        commit("initialize", true);
      });
    }

    // try to load in applications that were created withing expired session
    const applicationsInSessionStored: any = ls.get(GlobalStorageKeys.APPLICATIONS_IN_SESSION);
    if (Object.keys(applicationsInSessionStored || {}).length) {
      commit("setApplicationsInSession", applicationsInSessionStored);
    }

    const userAdditionalInfoStored: SellerInterface | null = ls.get(GlobalStorageKeys.APPLICATION_USER_ADDITIONAL_INFO);

    const isManagerWith2Fa = state.isManager && (state.user?.Emailas?.length || 0) > 0;

    if (userAdditionalInfoStored != undefined || isManagerWith2Fa) {
      const parsedAdditionalInfo: SellerInterface | null = userAdditionalInfoStored || null;

      commit("setAdditionalInfo", parsedAdditionalInfo);

      // check for 2fa stuff
      const storedVerificationState: DeviceVerificationEntryInterface | null = ls.get(
        `${GlobalStorageKeys.DEVICE_VERIFICATION_COOKIE}-${user.user.userName}`
      );
      const is2FaPending = ls.get(GlobalStorageKeys.PENDING_2FA_VERIFICATION);
      commit("set2FaVerificationPending", is2FaPending);

      if (
        (+(parsedAdditionalInfo?.DviejuFaktoriuPrisijungimas ?? 0) === 1 || isManagerWith2Fa) &&
        storedVerificationState?.deviceId &&
        storedVerificationState.verified === true &&
        !is2FaPending
      ) {
        // then we need to perform real deviceCheck once more to make sure that localStorage information is correct
        const params: UserVerifyRequestParams = {
          [userVerifyActions.deviceCheck]: {
            vartotojoVardas: user.user.userName,
            sesija: user.user.Sesija,
            irenginioId: storedVerificationState?.deviceId,
            metodas: parsedAdditionalInfo?.Autentifikacija || SellerAuthentication.EMAIL,
          },
        };

        new SontClient()
          .sendRequest(userVerifyActions.deviceCheck, "POST", params)
          .then((res) => {
            if (!res.status) {
              commit("set2FaVerification", false);
            } else {
              commit("set2FaVerification", true);
            }
          })
          .catch((e) => {
            if (e.error === 410) {
              nextTick(() => {
                dispatch("setUserLoggedOut");
              });

              return;
            }

            console.log("error", e);
          });
      }
    }

    return dispatch("extendSession", +sessionExpiresStored).then(() => {
      // this time sync request is required because it will be skipped during extendSession dispatch if sessionExpiresStored is passed
      // so here we are verifying if existing session is still valid time vice
      return new TimeClient()
        .sendRequest(timeActions.timeNow, "GET", null)
        .then((res) => {
          if (state.isLoggedIn && !state.isManager && +(state.sessionExpires || 0) <= +res) {
            dispatch("notifications/showSessionExpiredError", sessionTimeout, { root: true });
            commit("setSessionExpired", true);
          }
        })
        .catch(() => {
          dispatch("notifications/showSessionExpiredError", sessionTimeout, { root: true });
          commit("setSessionExpired", true);
        })
        .finally(() => {
          commit("initialize", true);
        });
    });
  },
  loadUserFromStorage() {
    if (ls.get(GlobalStorageKeys.APPLICATION_USER)) {
      return { user: ls.get(GlobalStorageKeys.APPLICATION_USER || "") };
    }

    return {};
  },
  setUserLoggedIn({ commit }: ActionContext<typeof state, any>, payload: unknown) {
    commit("userLogin", payload);
  },
  setUser({ commit }: ActionContext<typeof state, any>, payload: unknown) {
    commit("setUser", payload);
  },
  setUserLoggedOut({ commit, dispatch }: ActionContext<typeof state, any>) {
    commit("userLogout");
    dispatch("sont/resetStore", {}, { root: true });
    dispatch("newApplication/resetStore", {}, { root: true });
    dispatch("sellers/resetStore", {}, { root: true });
    dispatch("notifications/dropAll", {}, { root: true });
    dispatch("notifications/dropNotificationByIndex", 0, { root: true });
    ls.remove(GlobalStorageKeys.APPLICATION_USER);
    ls.remove(GlobalStorageKeys.APPLICATION_USER_ADDITIONAL_INFO);
    commit("setAdditionalInfo", null);
    ls.remove(GlobalStorageKeys.SESSION_EXPIRES);
    commit("setApplicationsInSession", []);
    ls.remove(GlobalStorageKeys.APPLICATIONS_IN_SESSION);
    commit("set2FaVerification", false);
  },
  storeLocale({ commit }: ActionContext<typeof state, any>, payload: string) {
    commit("setLocale", payload);
  },
  notifyAboutPasswordUpdate({ commit }: ActionContext<typeof state, any>, updated: boolean) {
    commit("notifyAboutPasswordUpdate", updated);
  },
  notifyAboutUpdatedAccount({ commit }: ActionContext<typeof state, any>, updated: boolean) {
    commit("notifyAboutUpdatedAccount", updated);
  },
  setSessionTimeout({ commit }: ActionContext<typeof state, any>, payload: number) {
    commit("setSessionTimeout", payload);
  },
  extendSession({ commit, dispatch }: ActionContext<typeof state, any>, payload: null | number | string = null) {
    if (payload) {
      commit("setSessionExpires", +payload);
      return;
    }

    const sessionTimeout = parseInt(process.env.VUE_APP_SELLER_SESSION_TIMEOUT || "") || 30 * 60000;
    new TimeClient()
      .sendRequest(timeActions.timeNow, "GET", null)
      .then((res) => {
        commit("setSessionExpires", +res + state.sessionTimeout);
      })
      .catch(() => {
        dispatch("notifications/showSessionExpiredError", sessionTimeout, { root: true });
        commit("setSessionExpired", true);
      });
  },
  setApplicationsInSession({ commit }: ActionContext<typeof state, any>, payload: number | number[]) {
    commit("setApplicationsInSession", Array.isArray(payload) ? payload : [payload]);
  },
  setSessionExpired({ commit }, payload: boolean) {
    commit("setSessionExpired", payload);
  },
  setAdditionalInfo({ commit }, payload: SellerInterface | null) {
    commit("setAdditionalInfo", payload);
  },
  set2FaVerification({ commit }, payload: boolean) {
    commit("set2FaVerification", payload);
  },

  set2FaVerificationPending({ commit }, payload: boolean) {
    commit("set2FaVerificationPending", payload);
  },

  saveUserInBrowser({ state }, payload: any) {
    // this will trigger LocalStorage sync event on which other open tabs might react and to some stuff
    // make sure to use it when that other user/hardware identification/2fa stuff is already saved
    ls.set(GlobalStorageKeys.APPLICATION_USER, payload ?? state.user);
  },
};

const authModule: Module<any, any> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};

export default authModule;
