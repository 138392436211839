export enum routerPaths {
  ROOT = "/",
  LOGIN = "/login",
  VERIFY_DEVICE = "/verify-device",
  PASSWORD_RESET = "/reset-password/:token/:code",
  PASSWORD_RESET_WITH_LOGIN_INPUT = "/reset-password/:token/:code/login",
  PASSWORD_REMIND = "/password-remind",
  PASSWORD_UPDATE = "/password-update",
  LOGOUT = "/logout",
  NEWS = "/news",
  APPLICATIONS = "/applications",
  NEW_APPLICATION = "/applications/add",
  EDIT_APPLICATION_STEP2 = "/applications/add/2/:id",
  NEW_APPLICATION_STEP2 = "/applications/add/2",
  NEW_APPLICATION_STEP3 = "/applications/add/3",
  NEW_APPLICATION_STEP4 = "/applications/add/4",
  NEW_APPLICATION_STEP5 = "/applications/add/5",
  EDIT_APPLICATION_STEP3 = "/applications/edit/:id/3",
  EDIT_APPLICATION_STEP4 = "/applications/edit/:id/4",
  EDIT_APPLICATION_STEP5 = "/applications/edit/:id/5",
  LOAD_APPLICATION = "/applications/load/:id",
  SEARCH_APPLICATIONS = "/search",
  ACCOUNT_SETTINGS = "/settings",
  REPORTS = "/reports",
  SPREADSHEET = "/spreadsheet",
  ACCOUNTS_LIST = "/supervisor/accounts",
  ACCOUNT_EDIT = "/supervisor/accounts/edit/:id",
  ACCOUNT_CREATE = "/supervisor/accounts/create",
  RECOGNIZER = "/recognizer/:type/:hash",
  DEBT_REPORT = "/reports/debt-report/:userId/:id",
  PAGE_NOT_FOUND = "/404",
  PERMISSION_DENIED = "/permission-denied",
  AUTO_LOGIN_LIMITED = "/login/url", //?u=:user&p=:psw&applicationId=:id",
  AUTO_LOGIN = "/login/url_full", //?u=:user&p=:psw&applicationId=:id",
  IFRAME_LOGIN = "/frame/login",
}

export enum routeNames {
  ROOT = "/",
  LOGIN = "Login",
  VERIFY_DEVICE = "/verify-device",
  PASSWORD_RESET = "PasswordReset",
  PASSWORD_RESET_WITH_LOGIN_INPUT = "PasswordResetWithLoginInput",
  PASSWORD_REMIND = "PasswordRemind",
  PASSWORD_UPDATE = "PasswordUpdate",
  LOGOUT = "Logout",
  NEWS = "News",
  APPLICATIONS = "Applications",
  NEW_APPLICATION = "NewApplication",
  EDIT_APPLICATION_STEP2 = "EditApplicationStep2",
  NEW_APPLICATION_STEP2 = "NewApplicationStep2",
  NEW_APPLICATION_STEP3 = "NewApplicationStep3",
  NEW_APPLICATION_STEP4 = "NewApplicationStep4",
  NEW_APPLICATION_STEP5 = "NewApplicationStep5",
  EDIT_APPLICATION_STEP3 = "EditApplicationStep3",
  EDIT_APPLICATION_STEP4 = "EditApplicationStep4",
  EDIT_APPLICATION_STEP5 = "EditApplicationStep5",
  LOAD_APPLICATION = "LoadApplication",
  SEARCH_APPLICATIONS = "SearchApplication",
  ACCOUNT_SETTINGS = "AccountSettings",
  REPORTS = "Reports",
  SPREADSHEET = "Spreadsheet",
  ACCOUNTS_LIST = "AccountsList",
  ACCOUNT_EDIT = "/supervisor/accounts/edit/:id",
  ACCOUNT_CREATE = "/supervisor/accounts/create",
  RECOGNIZER = "Recognizer",
  DEBT_REPORT = "DebtReport",
  PAGE_NOT_FOUND = "PageNotFound",
  ERROR_404_PAGE = "404",
  PERMISSION_DENIED = "PermissionDenied",
  AUTO_LOGIN_LIMITED = "AutoLoginLimited",
  AUTO_LOGIN = "AutoLogin",
  IFRAME_LOGIN = "IframeLogin",
}

export const expiredSessionRoutes = [
  routerPaths.NEWS,
  routerPaths.NEW_APPLICATION,
  routerPaths.NEW_APPLICATION_STEP4,
  routerPaths.NEW_APPLICATION_STEP5,
  routerPaths.SPREADSHEET,
  routerPaths.LOGIN,
  routerPaths.PASSWORD_UPDATE,
  routerPaths.AUTO_LOGIN_LIMITED,
  routerPaths.AUTO_LOGIN,
  routeNames.RECOGNIZER,
  routerPaths.IFRAME_LOGIN,
];

export const notIntendedRoutes = [
  routerPaths.LOGIN,
  routerPaths.ROOT,
  routerPaths.LOGOUT,
  routerPaths.PAGE_NOT_FOUND,
  routerPaths.PASSWORD_RESET,
  routeNames.PASSWORD_RESET,
  routeNames.PASSWORD_RESET_WITH_LOGIN_INPUT,
  routerPaths.PASSWORD_UPDATE,
  routerPaths.PASSWORD_REMIND,
  routerPaths.PERMISSION_DENIED,
  routerPaths.RECOGNIZER,
  routeNames.RECOGNIZER,
  routerPaths.AUTO_LOGIN_LIMITED,
  routerPaths.AUTO_LOGIN,
  routerPaths.IFRAME_LOGIN,
];

export const notRedirectableRoutes = [routerPaths.AUTO_LOGIN, routerPaths.AUTO_LOGIN_LIMITED, routerPaths.IFRAME_LOGIN];

export const limitedRoutes = [routerPaths.AUTO_LOGIN_LIMITED];

export const expiredSessionRoutesWithParams = [routerPaths.EDIT_APPLICATION_STEP4, routerPaths.EDIT_APPLICATION_STEP5];

export function combineExpiredSessionRoutes(applicationId: number[]) {
  let routesInSession: string[] = [];
  applicationId.forEach((applicationInSession) => {
    routesInSession = [
      ...routesInSession,
      ...expiredSessionRoutesWithParams.map((item) => item.replaceAll(":id", applicationInSession.toString())),
    ];
  });

  return [...expiredSessionRoutes, ...routesInSession];
}

export const AutoRefreshRoutes = [routerPaths.RECOGNIZER]; // do not add login route here

export const RoutesWithoutMenu = [
  routeNames.ROOT,
  routeNames.LOGIN,
  routeNames.LOGOUT,
  routeNames.DEBT_REPORT,
  routeNames.RECOGNIZER,
  routeNames.PASSWORD_UPDATE,
  routeNames.AUTO_LOGIN_LIMITED,
  routeNames.AUTO_LOGIN,
  routeNames.IFRAME_LOGIN,
];
