<template>
  <div>
    <verification-modal
      v-model="showVerificationCodeModal"
      @closed="onCloseVerificationModal"
      @verified="onSuccessfulVerification($event)"
      @sending-result="verificationSendingResult"
      :user-information="form"
      account-update
    />
    <b-alert variant="danger" show v-if="errorMessage !== ''"> {{ errorMessage | capitalize }} </b-alert>
    <slot></slot>
    <b-form-group v-if="userData" :label="$t('accountSettings.authenticationMethod') | capitalize" label-for="authenticationMethod">
      <searchable-select
        id="authenticationMethod"
        name="authenticationMethod"
        class="border-gray-1"
        :options="authenticationOptions"
        v-model="form.authenticationMethod"
        v-validate="{
          excluded: AuthenticationMethods.NONE,
        }"
        :state="validateState('authenticationMethod')"
        aria-describedby="authentication-method-feedback"
        :data-vv-as="$t('accountSettings.authenticationMethod') | capitalize"
        text-field="text"
        value-field="value"
        no-filter
        no-filter-input
        no-blank-option
      ></searchable-select>
      <b-form-invalid-feedback :id="`authentication-method-feedback`">
        {{ veeErrors.first("authenticationMethod") }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group :label="$t('accountSettings.email') | capitalize" label-for="emailMapper">
      <b-form-input
        id="emailMapper"
        name="email"
        class="border-gray-1 no-bg-image"
        v-model="form.email"
        type="email"
        v-validate.immediate="{
          required: form.authenticationMethod == AuthenticationMethods.EMAIL,
          email: true,
          emailFirstPart: true,
        }"
        :state="validateState('email')"
        aria-describedby="email-feedback"
        :data-vv-as="$t('accountSettings.email')"
        title=""
      ></b-form-input>
      <b-form-invalid-feedback :id="`email-feedback`">
        {{ veeErrors.first("email") }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group v-if="userData" :label="$t('accountSettings.phone') | capitalize" label-for="phoneMapper" label-class="color-gray-5">
      <b-input-group>
        <template #prepend>
          <div class="input-group-text bg-white text-muted">+370</div>
        </template>
        <b-form-input
          id="phoneMapper"
          name="phone"
          class="border-gray-1 no-bg-image"
          type="text"
          v-model="form.phone"
          :formatter="dashedPhoneFromValue"
          placeholder="6XX XXXXX"
          v-validate.immediate="{
            required: form.authenticationMethod == AuthenticationMethods.SMS,
            regex: constantPhoneRegex,
          }"
          :state="validateState('phone')"
          aria-describedby="phone-feedback"
          :data-vv-as="$t('accountSettings.phone') | capitalize"
          inputmode="decimal"
          autocomplete="new-password"
        ></b-form-input>
        <b-form-invalid-feedback :id="`phone-feedback`">{{ veeErrors.first("phone") }}</b-form-invalid-feedback>
      </b-input-group>
    </b-form-group>

    <b-form-group :label="$t('accountSettings.newPassword') | capitalize" label-for="newPassword">
      <b-form-input name="username" autocomplete="username" class="d-no-ne h-0" v-model="form.userName" />
      <b-input-group>
        <b-form-input
          autocomplete="new-password"
          id="newPassword"
          class="border-gray-1"
          :class="{ 'no-bg-image': !form.password.length && !form.passwordConfirmation.length }"
          v-model="form.password"
          :type="passwordType1"
          name="password"
          v-validate.immediate="{
            required: true,
          }"
          :data-vv-as="$t('accountSettings.newPassword') | capitalize"
          :state="passwordValid"
          ref="password"
        ></b-form-input>
        <password-toggler v-model="passwordType1"></password-toggler>
        <b-form-invalid-feedback id="newPassword-feedback">
          <span v-html="passwordErrors(form.password)"></span>
        </b-form-invalid-feedback>
      </b-input-group>
    </b-form-group>

    <b-form-group :label="$t('accountSettings.passwordConfirmation') | capitalize" label-for="passwordConfirmation">
      <b-input-group>
        <b-form-input
          autocomplete="new-password"
          id="passwordConfirmation"
          class="border-gray-1"
          :class="{ 'no-bg-image': !form.password.length && !form.passwordConfirmation.length }"
          v-model="form.passwordConfirmation"
          :type="passwordType2"
          name="passwordConfirmation"
          v-validate.immediate="{
            required: true,
            confirmed: form.password,
          }"
          :data-vv-as="$t('accountSettings.passwordConfirmation') | capitalize"
          :state="validateState('passwordConfirmation')"
        ></b-form-input>
        <password-toggler v-model="passwordType2"></password-toggler>
        <b-form-invalid-feedback id="passwordConfirmation-feedback">
          {{ veeErrors.first("passwordConfirmation") }}
        </b-form-invalid-feedback>
      </b-input-group>
    </b-form-group>

    <b-button
      class="w-100 text-white btn-sm p-3 rounded-more"
      variant="primary"
      :disabled="isSaveButtonDisabled"
      @click.prevent="submitAccountForm"
    >
      <b-spinner v-if="saving" small class="my-auto mr-2" />
      {{ $t("login.saveData") | capitalize }}
    </b-button>
  </div>
</template>

<script lang="ts">
import mixins from "vue-typed-mixins";
import soapRequests from "@/mixins/soapRequests";
import validation from "@/mixins/validation";
import PasswordToggler from "@/components/passwordToggler.vue";
import { SellerInterface } from "@/interfaces/seller";
import { AuthenticationMethods } from "@/interfaces/applicationSettings";
import VerificationModal from "./verificationModal.vue";
import authenticationOptions from "@/mixins/authenticationOptions";
import userVerifyRequests from "@/mixins/userVerifyRequests";
import { mapActions } from "vuex";
import { GlobalStorageKeys } from "@/interfaces/applicationSettings";
import { SoapErrors, SoapOK } from "@/lib/soap/soapParams";
import { routerPaths } from "@/router/routerPaths";
import accountUpdate from "@/mixins/accountUpdate";
import SearchableSelect from "@/components/searchableSelect.vue";
import { userVerifyActions } from "@/lib/userVerify/userVerifyParams";
import SontClient from "@/lib/userVerify/userVerifyClient";
import { phoneNoCode } from "@/customizations/filters";
import punycode from "@/lib/punycode";

export default mixins(soapRequests, userVerifyRequests, validation, authenticationOptions, accountUpdate).extend({
  name: "AccountUpdateForm",
  components: {
    PasswordToggler,
    VerificationModal,
    SearchableSelect,
  },
  props: {
    userData: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      errorMessage: "",
      showVerificationCodeModal: false,
      saving: false,
      form: {
        name: "",
        surname: "",
        email: "",
        password: "",
        passwordConfirmation: "",
        phone: "",
        authenticationMethod: 0,
        userName: "",
        shop: "",
      },
      lastVerifiedContacts: {
        phone: "",
        email: "",
        authenticationMethod: 0,
      },
      passwordType: "password",
      passwordType1: "password",
      passwordType2: "password",
      AuthenticationMethods,
    };
  },
  mounted() {
    this.initFormData();
  },
  methods: {
    ...mapActions({
      notifyAboutUpdatedAccount: "auth/notifyAboutUpdatedAccount",
      setUser: "auth/setUser",
      setUserLoggedOut: "auth/setUserLoggedOut",
      set2FaVerification: "auth/set2FaVerification",
    }),
    onSuccessfulVerification(verificationToken: string) {
      this.showVerificationCodeModal = false;

      const email = punycode.ToUnicode(this.form.email) || this.form.email;

      this.lastVerifiedContacts = {
        authenticationMethod: this.form.authenticationMethod,
        email: email,
        phone: this.form.phone,
      };

      this.saveSeller(verificationToken);
    },
    saveSeller(verificationToken?: string) {
      this.clearNotifications();
      this.saving = true;
      const sellerUserInfo: SellerInterface = this.userData.PardavejoVartotojas;
      const email = punycode.ToUnicode(this.form.email) || this.form.email;

      const userInformation: SellerInterface = {
        ...this.$props.userData.PardavejoVartotojas,
        Emailas: email,
        MobilusTelefonas: this.phoneFromValue(this.form.phone),
        Telefonas: this.phoneFromValue(this.form.phone),
        Loginas: sellerUserInfo.Loginas,
        ParduotuvesID: this.form.shop,
        Autentifikacija: this.form.authenticationMethod,
        ...this.getVerifiedFieldsValues(sellerUserInfo, this.form.authenticationMethod, this.phoneFromValue(this.form.phone) || "", email),
      };

      return this.soapIssaugotiPardaveja(userInformation, this.form.password)
        .then((response: { KlaidosKodas: string }) => {
          if (response?.KlaidosKodas == SoapOK) {
            this.performDeviceVerification(verificationToken);

            this.notifyAboutUpdatedAccount(true);
            this.saveSellerInStore();
            this.$router.push({ path: routerPaths.NEWS });
          } else {
            this.errorMessage = this.$t(`soap.${response.KlaidosKodas}`) as string;
            this.logOutOnSessionError(response?.KlaidosKodas);
          }
        })
        .catch((error) => {
          this.errorMessage = this.$t(`soap.${error.KlaidosKodas}`) as string;
          this.logOutOnSessionError(error?.KlaidosKodas);
        })
        .finally(() => (this.saving = false));
    },
    async performDeviceVerification(verificationToken?: string) {
      if (!verificationToken?.length) {
        return;
      }

      const requestAction = userVerifyActions.accountUpdateRecheckDevice;
      const params = {
        [userVerifyActions.accountUpdateRecheckDevice]: {
          sesija: this.user().Sesija,
          token: verificationToken,
          metodas: this.form.authenticationMethod,
        },
      };

      const recheckDeviceResult = await new SontClient().sendRequest(requestAction, "POST", params);
      if (recheckDeviceResult.status) {
        this.$ls.set(
          `${GlobalStorageKeys.DEVICE_VERIFICATION_COOKIE}-${this.user().userName}`,
          { verified: true, deviceId: recheckDeviceResult.verified },
          { ttl: 90 * 24 * 60 * 60 }
        );
        this.set2FaVerification(true);
      }
    },
    logOutOnSessionError(errorCode: string | number | null) {
      if (errorCode && errorCode == SoapErrors.INCORRECT_SESSION) {
        // with timeout that user could see the error first and redirect after
        setTimeout(() => {
          this.setUserLoggedOut();
        }, 1000);
      }
    },
    initFormData(): void {
      const userData = this.userData.PardavejoVartotojas;

      this.form.name = userData.Vardas || "";
      this.form.surname = userData.Pavarde || "";
      this.form.email = userData.Emailas || "";
      this.form.phone = phoneNoCode(userData.MobilusTelefonas);
      this.form.authenticationMethod = userData.Autentifikacija || "0";
      this.form.userName = userData.Loginas || "";
      this.form.shop = userData.ParduotuvesID || "";
    },
    onCloseVerificationModal() {
      this.showVerificationCodeModal = false;
      this.saving = false;
    },
    submitAccountForm(): void {
      this.saving = true;
      this.clearNotifications();
      this.errorMessage = "";

      if (this.isAlreadyVerified) {
        this.saveSeller();
        return;
      }

      this.showVerificationCodeModal = true;
    },
    saveSellerInStore() {
      const email = punycode.ToUnicode(this.form.email) || this.form.email;
      const updatedParts = {
        Emailas: email,
        MobilusTelefonas: this.oldDashedPhoneFromValue(this.form.phone),
        Autentifikacija: this.form.authenticationMethod,
        Loginas: this.form.userName,
        Sesija: this.user().Sesija,
      };

      const seller = this.user().PardavejoVartotojas;

      const updatedUser: any = {
        ...this.user(),
        ...updatedParts,
        PardavejoVartotojas: {
          ...seller,
          ...updatedParts,
          ...this.getVerifiedFieldsValues(
            seller,
            this.form.authenticationMethod,
            this.oldDashedPhoneFromValue(this.form.phone) || "",
            email
          ),
        },
      };

      this.setUser(updatedUser);
      this.$ls.set(GlobalStorageKeys.APPLICATION_USER, updatedUser);
    },
    verificationSendingResult(wasError: boolean) {
      this.saving = false;
      this.clearNotifications();

      if (wasError) {
        const messageKey =
          this.form.authenticationMethod == AuthenticationMethods.EMAIL
            ? "accountSettings.sendingErrorEmail"
            : "accountSettings.sendingErrorSms";

        this.errorMessage = this.$options.filters?.capitalize(this.$t(messageKey));
      }

      this.showVerificationCodeModal = false;
    },
  },
  computed: {
    isSaveButtonDisabled: function (): boolean {
      return this.$validator.errors.items.length > 0 || this.saving || this.passwordErrors(this.form.password).length > 0;
    },
    passwordValid: function (): boolean {
      return this.validateState("password") && this.strongPasswordExist(this.form.password);
    },
    isAlreadyVerified: function (): boolean {
      if (this.form.authenticationMethod !== this.lastVerifiedContacts.authenticationMethod) {
        return false;
      }

      if (this.form.authenticationMethod === AuthenticationMethods.EMAIL) {
        const email = punycode.ToUnicode(this.form.email) || this.form.email;
        return email === this.lastVerifiedContacts.email;
      }

      if (this.lastVerifiedContacts.authenticationMethod === AuthenticationMethods.SMS) {
        return this.form.phone === this.lastVerifiedContacts.phone;
      }

      return false;
    },
  },
});
</script>
