import { currency } from "@/customizations/filters";
import {
  ApplicationInterface,
  GoodsInterface,
  PrekeInterface,
  ProductCatalogTypes,
  WarrantyExtensionPlanInterface,
} from "@/interfaces/application";
import {
  ApplicationSettingsInterface,
  ContractStatuses,
  ContractTypes,
  CreditConditionsOptionInterface,
  CreditTypes,
  InitiationConditions,
  ProposedOptionInterface,
} from "@/interfaces/applicationSettings";
import { BuyerInterface, BuyerSpouseInterface } from "@/interfaces/buyer";
import { SellerInterface } from "@/interfaces/seller";
import { ActionContext, Module } from "vuex";

// state

export const state = {
  seller: null,
  buyer: null,
  buyerSpouse: null,
  application: null,
  applicationSettings: null,
  proposedOptions: [],
  goods: [],
  goodsWarrantyPlan: null as WarrantyExtensionPlanInterface | null,
  applicationStep: 0,
  pendingState: null, // this will be used to initiate something after full reset to defaults,
  keepProposedOptions: false,
  clientDataUpdated: null,
  spouseDataUpdated: null,
  signOnTabletAlert: false,
  creditConditionsOptions: [],
  invalidCreditConditionIDs: [] as number[],
  invalidCreditConditionIDsByGoods: [] as number[],
  isGoodsContract: false,
  atLeastOneGoodIsValid: false,
};

// getters
export const getters = {
  signOnTabletAlert: (state: { signOnTabletAlert: boolean }) => state.signOnTabletAlert,
  clientDataUpdated: (state: { clientDataUpdated: boolean }) => state.clientDataUpdated,
  spouseDataUpdated: (state: { spouseDataUpdated: boolean }) => state.spouseDataUpdated,
  seller: (state: { seller: SellerInterface }) => state.seller,
  buyer: (state: { buyer: BuyerInterface }) => state.buyer,
  buyerSpouse: (state: { buyerSpouse: BuyerSpouseInterface }) => state.buyerSpouse,
  application: (state: { application: ApplicationInterface }) => state.application,
  applicationSettings: (state: { applicationSettings: ApplicationSettingsInterface }) => state.applicationSettings,
  proposedOptions: (state: { proposedOptions: ProposedOptionInterface[] }) => state.proposedOptions,
  goods: (state: { goods: GoodsInterface[] }) => state.goods,
  goodsWarrantyPlan: (state: { goodsWarrantyPlan: WarrantyExtensionPlanInterface }) => state.goodsWarrantyPlan,
  applicationStep: (state: { applicationStep: number }) => state.applicationStep,
  isContract: (state: { application: ApplicationInterface }) => {
    if (!state.application || !state.application?.Busena) {
      return false;
    }

    // @todo: try to refactor magic numbers
    return [1, "1", 2, "2", 3, "3", 7, "7"].indexOf(state.application.Busena) !== -1;
  },
  isSignedContract: (state: { application: ApplicationInterface }) => {
    if (!state.application || !state.application?.Busena) {
      return false;
    }

    return [ContractStatuses.SIGNED].indexOf(+state.application.Busena) !== -1;
  },
  isGoodsContract: (state: { isGoodsContract: boolean }) => {
    return state.isGoodsContract;
  },
  isRemotePossibleContract: (state: {
    application: { SalyguVariantai: { SalyguVariantas: [{ SutartiesTipoID: number }] | { SutartiesTipoID: number } } };
    applicationSettings: ApplicationSettingsInterface;
  }) => {
    if (state.application) {
      const salyguVariantsArray = Array.isArray(state.application.SalyguVariantai.SalyguVariantas)
        ? state.application.SalyguVariantai.SalyguVariantas
        : [state.application.SalyguVariantai.SalyguVariantas];

      return (
        +(state.applicationSettings.creditType || 0) !== +CreditTypes.SPOUSE &&
        salyguVariantsArray?.some((item: { SutartiesTipoID: number }) => {
          return [ContractTypes.GOODS, ContractTypes.CREDIT].indexOf(+item.SutartiesTipoID) !== -1;
        })
      );
    }

    return false;
  },
  isSignInitiationPossible: (state: { application: ApplicationInterface }) => {
    if (!state.application || !state.application?.InicijavimasGalimas) {
      return false;
    }

    return [
      InitiationConditions.ALLOWED,
      InitiationConditions.ALLOWED_WHEN_EL_SIGNED,
      InitiationConditions.ALLOWED_WITH_ATTACHED_DOCUMENTS,
    ].includes(+state.application.InicijavimasGalimas);
  },
  isCacheContract: (state: { application: { SutartiesTipas: number } }) => {
    if (state.application && [ContractTypes.MONEY_IN_CACHE].indexOf(+state.application.SutartiesTipas) !== -1) {
      return true;
    }

    return false;
  },
  creditConditionsOptions: (state: { creditConditionsOptions: CreditConditionsOptionInterface[] }) => {
    return state.creditConditionsOptions;
  },
  goodCreditConditionsOptions: (state: { creditConditionsOptions: CreditConditionsOptionInterface[] }) => {
    return state.creditConditionsOptions.filter((item: CreditConditionsOptionInterface) => {
      return +item.SutartiesTipoID === ContractTypes.GOODS || +item.SutartiesTipoID === ContractTypes.CREDIT;
    });
  },
  isCatalogDisabled: (state: { application: { KatalogoTipas: string | number } }) => {
    return state.application && +state.application.KatalogoTipas === ProductCatalogTypes.DISABLED;
  },
  isCreditContract: (state: { application: { SutartiesTipas: ContractTypes } }) => {
    if (state.application && +state.application.SutartiesTipas == ContractTypes.CREDIT) {
      return true;
    }

    return false;
  },
  isArchive: (state: { application: { Busena: ContractStatuses } }) => {
    if (state.application && state.application.Busena == ContractStatuses.ARCHIVE) {
      return true;
    }

    return false;
  },
  pendingState: (state: { pendingState: any }) => {
    return state.pendingState;
  },
  shouldKeepProposedOptions: (state: { keepProposedOptions: boolean }) => {
    return state.keepProposedOptions;
  },
  invalidCreditConditionIDs: (state: { invalidCreditConditionIDs: number[] }) => {
    return state.invalidCreditConditionIDs;
  },
  invalidCreditConditions: (state: {
    application: ApplicationInterface;
    invalidCreditConditionIDs: number[];
    invalidCreditConditionIDsByGoods: number[];
  }) => {
    return +(state.application.KatalogoAkcijosIsimtis || 0) === 1
      ? []
      : [...state.invalidCreditConditionIDs, ...state.invalidCreditConditionIDsByGoods];
  },
  goodsBlockValid: (state: {
    application: ApplicationInterface;
    goods: GoodsInterface[];
    isGoodsContract: boolean;
    invalidCreditConditionIDs: number[];
    invalidCreditConditionIDsByGoods: number[];
    atLeastOneGoodIsValid: boolean;
  }): boolean => {
    // check if goods must be filled and isn't
    if (
      state.isGoodsContract &&
      (!state.goods.length || state.goods.reduce((sum: number, good: GoodsInterface) => (sum += +good.price), 0) === 0)
    ) {
      return false;
    }

    // turn off validation in case if exception is used
    if (+(state.application.KatalogoAkcijosIsimtis || 0) === 1) {
      return true;
    }

    // so we have two options here
    // B (application.KatalogoTipas === 1) - means all of the goods with provided ID should match selected credit condition
    // A (application.KatalogoTipas === 2) - means at least one of the goods with provided ID should be with some actionVariant that matches selected credit condition
    let result = true;

    let promotionalCondition = false;
    if (Array.isArray(state.application.SalyguVariantai?.SalyguVariantas)) {
      promotionalCondition =
        state.application.SalyguVariantai?.SalyguVariantas.some(
          (option: CreditConditionsOptionInterface) =>
            option.SalyguVariantoID === state.application.SalyguVariantoID && +option.Akcijinis === 1
        ) || false;
    }

    // skip validation if selected credit condition is not marked as promo (Akcijinis !== 1)
    if (!promotionalCondition) {
      return result;
    }

    const goodsWithAction = state.goods.filter(
      (good: GoodsInterface) =>
        !good.id ||
        !good.actionVariants ||
        !good.actionVariants.length ||
        good.actionVariants.some((variant) => +variant === +(state.application?.SalyguVariantoID || 0))
    );

    // combined actionVariants from all goods
    const combinedActionVariants: (string | number)[] = [...state.invalidCreditConditionIDs, ...state.invalidCreditConditionIDsByGoods];

    switch (+(state.application.KatalogoTipas || 0)) {
      case ProductCatalogTypes.TYPE_A:
        if (promotionalCondition && !state.atLeastOneGoodIsValid) {
          result = false;
        }

        break;
      case ProductCatalogTypes.TYPE_B:
        if (
          goodsWithAction.length &&
          combinedActionVariants.some((v: string | number) => +(state.application?.SalyguVariantoID || 0) === +v)
        ) {
          result = false;
        }
        break;
      default:
        // this means catalog is disabled and function will be already terminated
        break;
    }

    return result;
  },
  atLeastOneGoodIsValid: (state: { atLeastOneGoodIsValid: boolean }): boolean => {
    return state.atLeastOneGoodIsValid;
  },
  canExtendWarranty: (state: { application: ApplicationInterface }): boolean => {
    return +(state.application?.PratestaGarantijaGalima || 0) > 0;
  },
  goodsTotalPrice: (state: { goods: GoodsInterface[] }): number => {
    const price = state.goods.reduce((carry: number, item: GoodsInterface) => carry + Number(item.price) * +item.quantity, 0);

    return +currency(price);
  },
  goodsTotalInsurance: (state: { goods: GoodsInterface[]; application: ApplicationInterface }): number => {
    if (!+(state.application?.PratestaGarantijaGalima || 0)) {
      return 0;
    }

    const price = state.goods.reduce(
      (sum: number, item: GoodsInterface) => sum + ((item.insured ? Number(item.warrantySum) * +item.quantity : 0) || 0),
      0
    );

    return +currency(price);
  },
  isCatalogWithException: (state: { application: ApplicationInterface }): boolean => {
    return +(state.application?.KatalogoAkcijosIsimtis || 0) === 1;
  },
};

// mutations
export const mutations = {
  setSignOnTabletAlert(state: { signOnTabletAlert: boolean }, signOnTabletAlert: boolean) {
    state.signOnTabletAlert = signOnTabletAlert;
  },
  setClientDataUpdated(state: { clientDataUpdated: boolean }, clientDataUpdated: boolean) {
    state.clientDataUpdated = clientDataUpdated;
  },
  setSpouseDataUpdated(state: { spouseDataUpdated: boolean }, spouseDataUpdated: boolean) {
    state.spouseDataUpdated = spouseDataUpdated;
  },
  setSeller(state: { seller?: SellerInterface }, seller: SellerInterface) {
    state.seller = seller;
  },
  setBuyer(state: { buyer?: BuyerInterface }, buyer: BuyerInterface) {
    state.buyer = buyer;
  },
  setBuyerSpouse(state: { buyerSpouse?: BuyerInterface }, buyerSpouse: BuyerInterface) {
    state.buyerSpouse = buyerSpouse;
  },
  setApplication(
    state: {
      application?: ApplicationInterface;
      buyer?: BuyerInterface;
      buyerSpouse?: BuyerSpouseInterface;
      creditConditionsOptions: CreditConditionsOptionInterface[];
      invalidCreditConditionIDs: number[];
      invalidCreditConditionIDsByGoods: number[];
      goods: GoodsInterface[];
      isGoodsContract: boolean;
      atLeastOneGoodIsValid: boolean;
    },
    application: ApplicationInterface
  ) {
    state.application = application;
    const buyerData: BuyerInterface = {
      Vardas: application?.Vardas,
      Pavarde: application?.Pavarde,
      AsmensKodas: application?.AsmensKodas,
      ElPastas: application?.ElPastas,
      Pajamos: application?.Pajamos,
      Darboviete: application?.Darboviete,
      PajamuTipas: application?.PajamuTipas,
      PajamuPazyma: application?.PajamuPazyma,
      MazesPajamos: application?.MazesPajamos,
      BusimosPajamos: application?.BusimosPajamos,
      DuomenuPolitikosGavimas: application?.DuomenuPolitikosGavimas,
      Telefonas2: application?.Telefonas2,
    };

    state.buyer = buyerData;

    const spouseData: BuyerSpouseInterface = {
      SutuoktinioTelefonas: application?.SutuoktinioTelefonas,
      SutuoktinioVardas: application?.SutuoktinioVardas,
      SutuoktinioPavarde: application?.SutuoktinioPavarde,
      SutuoktinioAsmensKodas: application?.SutuoktinioAsmensKodas,
      SutuoktinioElPastas: application?.SutuoktinioElPastas,
      SutuoktinioPajamos: application?.SutuoktinioPajamos,
      SutuoktinioDarboviete: application?.SutuoktinioDarboviete,
      SutuoktinioPajamuTipas: application?.SutuoktinioPajamuTipas,
      SutuoktinioPajamuPazyma: application?.SutuoktinioPajamuPazyma,
      SutuoktinioMazesPajamos: application?.SutuoktinioMazesPajamos,
      SutuoktinioBusimosPajamos: application?.SutuoktinioBusimosPajamos,
      SutuoktinioDuomenuPolitikosGavimas: application?.DuomenuPolitikosGavimas,
    };

    state.buyerSpouse = spouseData;

    const creditConditionsOptions = !state.application?.SalyguVariantai
      ? []
      : Array.isArray(state.application?.SalyguVariantai?.SalyguVariantas)
      ? state.application?.SalyguVariantai?.SalyguVariantas
      : [state.application.SalyguVariantai?.SalyguVariantas];

    state.creditConditionsOptions = creditConditionsOptions as CreditConditionsOptionInterface[];

    // isGoodsContract
    if (state.application && [ContractTypes.GOODS, ContractTypes.CREDIT].indexOf(+(state.application.SutartiesTipas || 0)) !== -1) {
      state.isGoodsContract = true;
    } else {
      state.isGoodsContract = false;
    }

    //invalidCreditConditionIDs
    const idArray: number[] = [];
    // if invalid, no standard information print allowed, no contract possible
    if (creditConditionsOptions.length) {
      creditConditionsOptions.forEach((item: { Nebegalioja: string; SalyguVariantoID: string }) => {
        if (+item.Nebegalioja === 1) {
          idArray.push(+item.SalyguVariantoID);
        }
      });
    }

    state.invalidCreditConditionIDs = idArray;

    // invalidCreditConditionIDsByGoods
    if (state.isGoodsContract) {
      const idArray: number[] = [];

      // get list of available credit conditions from the goods list
      const conditionsFromGoods: number[] = [];
      state.goods.forEach((g: GoodsInterface) => {
        if (!g.actionVariants || !g.actionVariants.length) {
          return;
        }

        if (Array.isArray(g.actionVariants)) {
          conditionsFromGoods.push(...g.actionVariants.map((item) => +item));
        } else {
          conditionsFromGoods.push(+g.actionVariants);
        }
      });

      let found: any[] = [];
      const options = state.creditConditionsOptions as CreditConditionsOptionInterface[];

      switch (+(state.application.KatalogoTipas || 0)) {
        case ProductCatalogTypes.TYPE_A:
          found = options
            .filter((item) => !conditionsFromGoods.includes(+item.SalyguVariantoID))
            .filter((item) => +item.Akcijinis === 1)
            .map((i) => i.SalyguVariantoID);

          break;
        case ProductCatalogTypes.TYPE_B:
          found = options
            .filter((item) => !conditionsFromGoods.includes(+item.SalyguVariantoID))
            .filter((item) => +item.Akcijinis === 1)
            .map((i) => i.SalyguVariantoID);
          break;
        default:
          // this means catalog is disabled and function will be already terminated
          break;
      }

      idArray.push(...found);
      state.invalidCreditConditionIDsByGoods = idArray;

      // atLeastOneGoodIsValid
      const matchingGoods = state.goods.filter(
        (good: GoodsInterface) =>
          good.id !== undefined &&
          good.actionVariants?.length &&
          good.actionVariants.some((variant) => +variant === +(state.application?.SalyguVariantoID || 0))
      );

      state.atLeastOneGoodIsValid = matchingGoods.length > 0;
    }
  },
  setApplicationSettings(state: { applicationSettings?: ApplicationSettingsInterface }, applicationSettings: ApplicationSettingsInterface) {
    state.applicationSettings = applicationSettings;
  },
  setProposedOptions(state: { proposedOptions?: ProposedOptionInterface[] }, proposedOptions: ProposedOptionInterface[]) {
    state.proposedOptions = proposedOptions;
  },
  setGoods(state: { goods: GoodsInterface[] }, goods: GoodsInterface[]) {
    state.goods = goods;
  },
  setGoodsWarrantyPlan(
    state: { goodsWarrantyPlan: WarrantyExtensionPlanInterface | null },
    goodsWarrantyPlan: WarrantyExtensionPlanInterface | null
  ) {
    state.goodsWarrantyPlan = goodsWarrantyPlan;
  },
  setPendingState(state: { pendingState: any }, pendingState: any) {
    state.pendingState = pendingState;
  },
  setApplicationStep(state: { applicationStep: number }, applicationStep: number) {
    state.applicationStep = applicationStep;
  },
  applyPendingState(state: { pendingState: { application: any }; application: any }) {
    if (!state.pendingState) {
      return;
    }

    if (state.pendingState.application) {
      state.application = { ...state.application, ...state.pendingState.application };

      delete state.pendingState.application;
    }
    // @todo: make some changes to other state stuff if required
  },
  setKeepProposedOptions(state: { keepProposedOptions: boolean }, newState: boolean) {
    state.keepProposedOptions = newState;
  },
};

// actions
export const actions = {
  setSignOnTabletAlert({ commit }: ActionContext<any, any>, payload: boolean) {
    commit("setSignOnTabletAlert", payload);
  },
  setClientDataUpdated({ commit }: ActionContext<any, any>, payload: boolean) {
    commit("setClientDataUpdated", payload);
  },
  setSpouseDataUpdated({ commit }: ActionContext<any, any>, payload: boolean) {
    commit("setSpouseDataUpdated", payload);
  },
  resetStore({ commit, dispatch }: ActionContext<any, any>) {
    commit("setSeller", null);
    dispatch("resetForNewApplication");
  },
  resetForNewApplication({ commit }: ActionContext<any, any>) {
    commit("setBuyer", null);
    commit("setBuyerSpouse", null);
    commit("setApplication", null);
    commit("setApplicationSettings", null);
    commit("setProposedOptions", []);
    commit("setGoods", []);
    commit("setGoodsWarrantyPlan", null);
  },
  storeSeller({ commit }: ActionContext<any, any>, payload?: SellerInterface) {
    commit("setSeller", payload);
  },
  storeBuyer({ commit }: ActionContext<any, any>, payload?: BuyerInterface) {
    commit("setBuyer", payload);
  },
  patchBuyer({ commit, state }: ActionContext<any, any>, payload?: BuyerInterface) {
    commit("setBuyer", { ...state.buyer, ...payload });
    commit("setApplication", { ...state.application, ...{ ...state.buyer, ...payload } });
  },
  storeBuyerSpouse({ commit }: ActionContext<any, any>, payload?: BuyerInterface) {
    commit("setBuyerSpouse", payload);
  },
  patchBuyerSpouse({ commit, state }: ActionContext<any, any>, payload?: BuyerInterface) {
    commit("setBuyerSpouse", { ...state.buyerSpouse, ...payload });
    commit("setApplication", { ...state.application, ...{ ...state.buyerSpouse, ...payload } });
  },
  storeApplication({ commit }: ActionContext<any, any>, payload?: ApplicationInterface) {
    commit("setApplication", payload);
  },
  storeApplicationSettings({ commit }: ActionContext<any, any>, payload?: ApplicationSettingsInterface) {
    commit("setApplicationSettings", payload);
  },
  patchApplication({ commit, state }: ActionContext<any, any>, payload?: ApplicationInterface) {
    commit("setApplication", { ...state.application, ...payload });
  },
  storeProposedOptions({ commit }: ActionContext<any, any>, payload?: ProposedOptionInterface[] | ProposedOptionInterface) {
    let adoptedPayload = [];
    if (Array.isArray(payload)) {
      adoptedPayload = payload;
    } else {
      adoptedPayload.push(payload);
    }

    // sort proposed options by duration
    adoptedPayload = adoptedPayload.sort((a, b) => {
      return a && b ? a.Trukme - b.Trukme : 0;
    });

    commit("setProposedOptions", adoptedPayload);
  },
  storeGoods({ commit }: ActionContext<any, any>, payload?: GoodsInterface[] | GoodsInterface) {
    let adoptedPayload = [];
    if (Array.isArray(payload)) {
      adoptedPayload = payload;
    } else {
      adoptedPayload.push(payload);
    }

    commit("setGoods", adoptedPayload);
  },
  appendGoods({ commit }: ActionContext<any, any>, payload?: GoodsInterface[] | GoodsInterface) {
    let adoptedPayload = [];
    if (Array.isArray(payload)) {
      adoptedPayload = payload;
    } else {
      adoptedPayload.push(payload);
    }

    commit("setGoods", [...state.goods, ...adoptedPayload]);
  },
  storeGoodsWarrantyPlan({ commit }: ActionContext<any, any>, payload?: WarrantyExtensionPlanInterface) {
    commit("setGoodsWarrantyPlan", payload);
  },
  loadApplication({ commit, getters }: ActionContext<any, any>, payload?: ApplicationInterface) {
    commit("setApplication", payload);

    const buyer: BuyerInterface = {
      Vardas: payload?.Vardas,
      Pavarde: payload?.Pavarde,
      AsmensKodas: payload?.AsmensKodas,
      BusimosPajamos: payload?.BusimosPajamos,
      Darboviete: payload?.Darboviete,
      DuomenuPolitikosGavimas: payload?.DuomenuPolitikosGavimas,
      ElPastas: payload?.ElPastas,
      MazesPajamos: payload?.MazesPajamos,
      Pajamos: payload?.Pajamos,
      PajamuPazyma: payload?.PajamuPazyma,
      PajamuTipas: payload?.PajamuTipas,
      Telefonas2: payload?.Telefonas2,
    };

    commit("setBuyer", buyer);

    const buyerSpouse: BuyerSpouseInterface = {
      SutuoktinioTelefonas: payload?.SutuoktinioTelefonas,
      SutuoktinioVardas: payload?.SutuoktinioVardas,
      SutuoktinioPavarde: payload?.SutuoktinioPavarde,
      SutuoktinioAsmensKodas: payload?.SutuoktinioAsmensKodas,
      SutuoktinioElPastas: payload?.SutuoktinioElPastas,
      SutuoktinioPajamos: payload?.SutuoktinioPajamos,
      SutuoktinioDarboviete: payload?.SutuoktinioDarboviete,
      SutuoktinioPajamuTipas: payload?.SutuoktinioPajamuTipas,
      SutuoktinioPajamuPazyma: payload?.SutuoktinioPajamuPazyma,
      SutuoktinioMazesPajamos: payload?.SutuoktinioMazesPajamos,
      SutuoktinioBusimosPajamos: payload?.SutuoktinioBusimosPajamos,
      SutuoktinioDuomenuPolitikosGavimas: payload?.DuomenuPolitikosGavimas,
    };

    commit("setBuyerSpouse", payload?.Sutuoktinis && parseInt(payload?.Sutuoktinis.toString()) > 0 ? buyerSpouse : {});

    let goodsResult: GoodsInterface[] = [];
    const goodsFromPayload = payload?.Prekes?.Preke;
    if (goodsFromPayload && !Array.isArray(goodsFromPayload) && Object.keys(goodsFromPayload).length) {
      // looks like only one item is here
      goodsResult.push({
        title: goodsFromPayload?.Pavadinimas,
        serialNo: goodsFromPayload?.SerijosNr,
        group: goodsFromPayload?.TurtoRusiesID,
        price: goodsFromPayload?.Kaina,
        quantity: goodsFromPayload?.Kiekis,
        insured: goodsFromPayload && goodsFromPayload.Apdrausta > 0,
        warrantySelected: goodsFromPayload?.GarantijosTrukme,
        warranty: goodsFromPayload?.GamintojoGarantija,
        warrantySum: goodsFromPayload?.GarantijosKaina,
        id: goodsFromPayload?.PrekesID,
        limitations: goodsFromPayload.Apribojimai,
      } as GoodsInterface);
    } else if (Array.isArray(goodsFromPayload)) {
      goodsFromPayload.forEach((element: PrekeInterface) =>
        goodsResult.push({
          title: element.Pavadinimas,
          serialNo: element.SerijosNr,
          group: element.TurtoRusiesID,
          price: element.Kaina,
          quantity: element.Kiekis,
          insured: element.Apdrausta > 0, //
          warrantySelected: element?.GarantijosTrukme, // number, months
          warranty: element?.GamintojoGarantija, // warranty of manufacturer
          warrantySum: element?.GarantijosKaina, // extended warranty sum
          id: element?.PrekesID,
          limitations: element.Apribojimai,
        } as GoodsInterface)
      );
    }

    // see what is inside of limitations and bring actionVariants if they exists
    goodsResult = goodsResult.map((g) => {
      try {
        if (!getters.isCatalogDisabled && g.limitations) {
          const limits = JSON.parse(g.limitations);
          if (limits.actionVariants) {
            return { ...g, ...{ actionVariants: limits.actionVariants } };
          }
        }
      } catch {
        return g;
      }

      return g;
    });

    commit("setGoods", goodsResult);

    commit("setApplicationSettings", { creditType: payload?.Sutuoktinis ? +payload?.Sutuoktinis : 1 });
    if (!state.keepProposedOptions) {
      commit("setProposedOptions", []);
    }
  },
  storePendingState({ commit }: ActionContext<any, any>, payload?: any) {
    commit("setPendingState", payload);
  },
  applyPendingState({ commit }: ActionContext<any, any>) {
    commit("applyPendingState");
  },
  storeApplicationStep({ commit }: ActionContext<typeof state, any>, payload?: number) {
    commit("setApplicationStep", payload);
  },
  storeKeepProposedOptions({ commit }: ActionContext<typeof state, any>, payload: boolean) {
    commit("setKeepProposedOptions", payload);
  },
};

const newApplicationsModule: Module<any, any> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};

export default newApplicationsModule;
