import Vue from "vue";
import VueRouter, { NavigationFailureType, RouteConfig, isNavigationFailure } from "vue-router";
import store from "@/store";
import {
  combineExpiredSessionRoutes,
  limitedRoutes,
  notIntendedRoutes,
  notRedirectableRoutes,
  routeNames,
  routerPaths,
} from "./routerPaths";
import { AuthenticationMethods, GlobalStorageKeys } from "@/interfaces/applicationSettings";
import { SoapErrors } from "@/lib/soap/soapParams";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: routerPaths.ROOT,
    redirect: routerPaths.NEWS,
  },
  {
    path: routerPaths.NEWS,
    name: routeNames.NEWS,
    component: () => import(/* webpackChunkName: "news" */ "@/views/News.vue"),
    meta: { title: "meta.titles.news" },
  },
  {
    path: routerPaths.LOAD_APPLICATION,
    name: routeNames.LOAD_APPLICATION,
    component: () => import(/* webpackChunkName: "loadApplication" */ "@/views/LoadApplication.vue"),
    meta: { title: "meta.titles.applications" },
  },
  {
    path: routerPaths.APPLICATIONS,
    name: routeNames.APPLICATIONS,
    component: () => import(/* webpackChunkName: "applications" */ "@/views/Applications.vue"),
    meta: { title: "meta.titles.applications" },
  },
  {
    path: routerPaths.NEW_APPLICATION,
    name: routeNames.NEW_APPLICATION,
    component: () => import(/* webpackChunkName: "newApplication" */ "@/views/NewApplication.vue"),
    meta: { title: "meta.titles.newApplication" },
  },
  {
    path: routerPaths.NEW_APPLICATION_STEP4,
    name: routeNames.NEW_APPLICATION_STEP4,
    component: () => import(/* webpackChunkName: "newApplication" */ "@/views/steps/NewStep4.vue"),
    meta: { title: "meta.titles.newApplicationStep4" },
  },
  {
    path: routerPaths.NEW_APPLICATION_STEP5,
    name: routeNames.NEW_APPLICATION_STEP5,
    component: () => import(/* webpackChunkName: "newApplication" */ "@/views/steps/NewStep5.vue"),
    meta: { title: "meta.titles.newApplicationStep5" },
  },
  {
    path: routerPaths.EDIT_APPLICATION_STEP4,
    name: routeNames.EDIT_APPLICATION_STEP4,
    component: () => import(/* webpackChunkName: "editApplication" */ "@/views/steps/EditStep4.vue"),
    meta: { title: "meta.titles.editApplicationStep4" },
  },
  {
    path: routerPaths.EDIT_APPLICATION_STEP5,
    name: routeNames.EDIT_APPLICATION_STEP5,
    component: () => import(/* webpackChunkName: "editApplication" */ "@/views/steps/EditStep5.vue"),
    meta: { title: "meta.titles.editApplicationStep5" },
  },
  {
    path: routerPaths.SEARCH_APPLICATIONS,
    name: routeNames.SEARCH_APPLICATIONS,
    component: () => import(/* webpackChunkName: "searchApplications" */ "@/views/SearchApplications.vue"),
    meta: { title: "meta.titles.searchApplications" },
  },
  {
    path: routerPaths.ACCOUNT_SETTINGS,
    name: routeNames.ACCOUNT_SETTINGS,
    component: () => import(/* webpackChunkName: "accountSettings" */ "@/views/accounts/AccountSettings.vue"),
    meta: {
      title: "meta.titles.accountSettings",
      requiresManager: true,
      alternativePermissionFailPath: routerPaths.NEWS,
    },
  },
  {
    path: routerPaths.REPORTS,
    name: routeNames.REPORTS,
    component: () => import(/* webpackChunkName: "reports" */ "@/views/Reports.vue"),
    meta: { title: "meta.titles.reports" },
  },
  {
    path: routerPaths.SPREADSHEET,
    name: routeNames.SPREADSHEET,
    component: () => import(/* webpackChunkName: "spreadsheet" */ "@/views/Spreadsheet.vue"),
    meta: { title: "meta.titles.spreadsheet" },
  },
  {
    path: routerPaths.ACCOUNTS_LIST,
    name: routeNames.ACCOUNTS_LIST,
    component: () => import(/* webpackChunkName: "accounts" */ "../views/accounts/AccountsList.vue"),
    meta: { title: "meta.titles.accounts", requiresSupervisor: true },
  },
  // routes without authentication
  {
    path: routerPaths.LOGIN,
    name: routeNames.LOGIN,
    component: () => import(/* webpackChunkName: "login" */ "@/views/Login.vue"),
    meta: { notRequiresAuth: true, title: "meta.titles.login" },
  },
  {
    path: routerPaths.AUTO_LOGIN_LIMITED,
    name: routeNames.AUTO_LOGIN_LIMITED,
    component: () => import(/* webpackChunkName: "autoLoginLimited" */ "../views/steps/LimitedEdit5.vue"),
    meta: { notRequiresAuth: true, title: "meta.titles.autoLoginLimited" },
  },
  {
    path: routerPaths.AUTO_LOGIN,
    name: routeNames.AUTO_LOGIN,
    component: () => import(/* webpackChunkName: "autoLogin" */ "../views/AutoLogin.vue"),
    meta: { notRequiresAuth: true, title: "meta.titles.autoLogin" },
  },
  {
    path: routerPaths.PASSWORD_RESET,
    name: routeNames.PASSWORD_RESET,
    component: () => import(/* webpackChunkName: "login" */ "@/views/PasswordRemind.vue"),
    meta: { notRequiresAuth: true, title: "meta.titles.passwordRemind" },
  },
  {
    path: routerPaths.PASSWORD_RESET_WITH_LOGIN_INPUT,
    name: routeNames.PASSWORD_RESET_WITH_LOGIN_INPUT,
    component: () => import(/* webpackChunkName: "login" */ "@/views/PasswordRemind.vue"),
    meta: { notRequiresAuth: true, title: "meta.titles.passwordRemind" },
  },
  {
    path: routerPaths.PASSWORD_REMIND,
    name: routeNames.PASSWORD_REMIND,
    component: () => import(/* webpackChunkName: "login" */ "@/views/PasswordRemind.vue"),
    meta: { notRequiresAuth: true, title: "meta.titles.passwordRemind" },
  },
  {
    path: routerPaths.PASSWORD_UPDATE,
    name: routeNames.PASSWORD_UPDATE,
    component: () => import(/* webpackChunkName: "login" */ "@/views/PasswordRemind.vue"),
    meta: { title: "meta.titles.passwordUpdate" },
  },
  {
    path: routerPaths.RECOGNIZER,
    name: routeNames.RECOGNIZER,
    component: () => import(/* webpackChunkName: "recognizer" */ "@/views/Recognizer.vue"),
    meta: { notRequiresAuth: true, title: "meta.titles.recognizer" },
  },
  {
    path: routerPaths.DEBT_REPORT,
    name: routeNames.DEBT_REPORT,
    component: () => import(/* webpackChunkName: "debt_report" */ "@/views/DebtReportPreview.vue"),
    meta: { title: "meta.titles.debtReport" },
  },
  {
    path: routerPaths.IFRAME_LOGIN,
    name: routeNames.IFRAME_LOGIN,
    component: () => import(/* webpackChunkName: "iframeLogin" */ "@/views/IframeLogin.vue"),
    meta: { notRequiresAuth: true, title: "meta.titles.iframeLogin" },
  },
  {
    path: routerPaths.PAGE_NOT_FOUND,
    name: routeNames.ERROR_404_PAGE,
    component: () => import(/* webpackChunkName: "page_not_found" */ "@/views/PageNotFound.vue"),
    meta: { notRequiresAuth: true, title: "meta.titles.pageNotFound" },
  },
  {
    path: routerPaths.PERMISSION_DENIED,
    name: routeNames.PERMISSION_DENIED,
    component: () => import(/* webpackChunkName: "page_not_found" */ "@/views/PageNotFound.vue"),
    props: { denied: true },
    meta: { title: "meta.titles.permissionDenied" },
  },
  {
    path: "*",
    name: routeNames.PAGE_NOT_FOUND,
    component: () => import(/* webpackChunkName: "page_not_found" */ "@/views/PageNotFound.vue"),
    meta: { notRequiresAuth: true, title: "meta.titles.pageNotFound" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // offload all the guard logic into method which will be called only after initialization of the store
  const routerGuards = () => {
    // not logged in case
    if (to.meta && !to.meta.notRequiresAuth && !store.getters["auth/isLoggedIn"]) {
      if (to.path.length && !notIntendedRoutes.includes(to.path as routerPaths) && !notIntendedRoutes.includes(to.name as routerPaths)) {
        router.app.$ls.set(GlobalStorageKeys.INTENDED_URL, to.path);

        const storedUser = router.app.$ls.get(GlobalStorageKeys.APPLICATION_USER);
        if (storedUser) {
          router.app.$ls.set(GlobalStorageKeys.INTENDED_ROLE, storedUser?.Role || "");
        }
      }

      // next({ path: routerPaths.LOGIN });
      router.replace({ path: routerPaths.LOGIN }).catch(() => {
        // next(false);
      });

      return;
    }

    // logged in case
    if (store.getters["auth/user"]) {
      // when logged in user goes into page where login is not used
      if (
        to.meta &&
        to.meta.notRequiresAuth &&
        to.path !== routerPaths.LOGIN &&
        to.name !== routeNames.RECOGNIZER &&
        to.path !== routerPaths.PAGE_NOT_FOUND &&
        !notRedirectableRoutes.includes(to.path as routerPaths)
      ) {
        router.replace({ path: routerPaths.PAGE_NOT_FOUND }).catch(() => {
          // next(false);
        });

        return;
      }

      // password update stuff
      if (
        to.path !== routerPaths.PASSWORD_UPDATE &&
        !store.getters["auth/isSessionExpired"] &&
        store.getters["auth/user"].KlaidosKodas == SoapErrors.PASSWORD_TOO_OLD &&
        (!store.getters["auth/is2FaEnabled"] || (store.getters["auth/is2FaEnabled"] && store.getters["auth/is2FaVerified"]))
      ) {
        // password update is possible only when 2fa is off or is already verified
        router.replace({ path: routerPaths.PASSWORD_UPDATE }).catch(() => {
          // next(false);
        });

        return;
      }

      // 2FA case
      if (store.getters["auth/is2FaEnabled"] && !store.getters["auth/is2FaVerified"] && to.path !== routerPaths.LOGIN) {
        // in 2FA case we will use some redirect to make it all happen
        // in order to redirect back we can use intended_url in LS - after completing 2FA flow user will be redirected to intended_url
        router.app.$ls.set(GlobalStorageKeys.INTENDED_URL, to.path);

        router.replace({ path: routerPaths.LOGIN }).catch((e) => {
          if (!isNavigationFailure(e, NavigationFailureType.duplicated)) {
            Promise.reject(e);
          }
          next(false);
        });

        return;
      }

      if (store.getters["auth/user"]?.PardavejoVartotojas?.Autentifikacija == AuthenticationMethods.NONE && to.path !== routerPaths.LOGIN) {
        router.replace({ path: routerPaths.LOGIN });

        return;
      }

      // user permissions check
      if (
        !store.getters["auth/isManager"] &&
        store.getters["auth/sessionExpires"] &&
        store.getters["auth/isSessionExpired"] &&
        !combineExpiredSessionRoutes(store.getters["auth/applicationsInSession"] || 0).includes(to.path as routerPaths) &&
        !combineExpiredSessionRoutes(store.getters["auth/applicationsInSession"] || 0).includes(to.name as routerPaths) &&
        (!store.getters["auth/is2FaEnabled"] || (store.getters["auth/is2FaEnabled"] && store.getters["auth/is2FaVerified"]))
      ) {
        store.dispatch("notifications/showSessionExpiredError", store.getters["auth/sessionTimeout"]);

        if (from.path !== routerPaths.NEWS && from.path !== routerPaths.NEW_APPLICATION && to.path !== routerPaths.NEWS) {
          next({ path: routerPaths.NEWS });
        }

        return;
      }

      const canSupervisor = to.meta && to.meta.requiresSupervisor && !store.getters["auth/isSupervisor"];
      const canManager = to.meta && to.meta.requiresManager && store.getters["auth/isManager"];

      if (canManager || canSupervisor) {
        router.push({ path: to?.meta?.alternativePermissionFailPath ?? routerPaths.PERMISSION_DENIED }).catch(() => {
          next(false);
        });

        return;
      }

      // limitedLogin case - user should be logged out if he enter zone that requires auth
      if (store.getters["auth/isLimitedLogin"] && !to.meta?.notRequiresAuth && !limitedRoutes.includes(to.path as routerPaths)) {
        // should be logged out
        store.dispatch("auth/setUserLoggedOut").then(() => {
          router.push({ path: routerPaths.LOGIN }).catch((e) => {
            if (
              !isNavigationFailure(e, NavigationFailureType.duplicated) ||
              !isNavigationFailure(e, NavigationFailureType.redirected) ||
              !isNavigationFailure(e, NavigationFailureType.aborted)
            ) {
              Promise.reject(e);
            }
          });
        });

        return;
      }

      next();

      return;
    }

    // not logged in
    next();
  };

  // check if store is already ready
  if (!store.getters["auth/isInitialized"]) {
    store.dispatch("auth/initialize").then(() => {
      routerGuards();
    });

    return;
  }

  routerGuards();
});

router.afterEach(() => {
  // drop notifications when navigating to new place

  if (store.getters["notifications/keepNotificationsOnRouteChangeOnce"]) {
    store.commit("notifications/setKeepNotificationsOnRouteChangeOnce", false);
  } else {
    store.commit("notifications/resetItems", {});
  }
});

// this will make sure that vue-router will be synced to browser history state on navigation via browser back button
window.addEventListener(
  "popstate",
  function (event) {
    const windowObject = event.target as Window;

    router.app.$nextTick(() => {
      if (router.currentRoute?.path !== windowObject?.location.pathname) {
        router.replace({ path: windowObject.location.pathname });
      }
    });
  },
  false
);

export default router;
